// Teaser

.teaser {
    position: relative;

    a {
        text-decoration: none;
    }

    .teaser-row & {
        margin-bottom: $grid-gutter-width;

        @include media-breakpoint-down(sm) {
            max-width: 336px;
            margin-left: auto;
            margin-right: auto;
        }

        @include media-breakpoint-down(xs) {
            max-width: 294px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: $grid-gutter-width-lg;
        }
    }

    .teaser-title {
        position: absolute;
        left: 0;
        z-index: 100;
        //transform: rotate(90deg);
        transform-origin: left;
        bottom: 40px;
        color: $black;
        white-space: nowrap;
        font-size: 2rem;
        font-family: $font-family-sans-serif, sans-serif;

        @include media-breakpoint-up(md) {
            left: -10px;
        }
    }
}

.teaser-top {
    position: relative;
    height: 0;
    overflow: hidden;
    color: $white;
    padding-bottom: 150%;
    @include fluid-type($font-size-lg, $font-size-xxl);

    //margin-top: 100px;

    &:hover {
        picture img {
            transform: scale(1.1);
            transition: transform $transition-time $transition-ease;
        }
    }

    img {
        filter: brightness(0.7);
        transform: scale(1);
        transition: transform $transition-time $transition-ease;
    }

    picture {

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to top,rgba(0,0,0,.55) 0,rgba(0,0,0,0) 100%);
        }
    }


    .teaser-nav & {
        padding-bottom: 75%;

        .teaser-title {
            font-size: 1.375rem;

            .teaser-arrow {
                top: -3px;
            }
        }
    }

    .img-fluid {
        min-width: 100%;
    }

    .teaser-title {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        line-height: 1.1;
        padding: 1.75rem 1rem;
        color: $white;
        font-weight: $font-weight-light;
        margin-bottom: 0;

        @include media-breakpoint-up(xl) {
            padding: 1.75rem 1.5rem;
        }

        .teaser-arrow {
            position: relative;
            white-space: nowrap;
            top: -7px;
            font-size: 1.25rem;
        }
    }
}


.teaser-bottom {
    margin-top: 0.875rem;
    padding: 0 1rem;
    color: $black;
    margin-bottom: 20px;

    @include media-breakpoint-up(xl) {
        padding: 0 1.5rem;
    }

    .teaser-nav & {
        color: $black;
    }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
    .teaser-nav {
        .teaser-title {
            .teaser-arrow {
                font-size: 0.9375rem;
            }
        }

        .teaser-subtitle {
            font-size: 1rem;
        }
    }
}

.teaser-splide {
    padding-top: 50px;
    padding-bottom: 50px;

    .splide__arrows  {
       padding-top: 100px;
    }
}

.teaser-splide .splide__list .splide__slide:nth-of-type(2n) {
    transform: translateY(50px);
}

.teaser-splide .splide__list .splide__slide:nth-of-type(3n) {
    transform: translateY(25px);
}

.frame-background-black,
.frame-background-bg-image {
    .splide__arrow svg {
        filter: brightness(0) invert(1);
    }
}
.dropdown-menu-content .teaser .teaser-title,
.frame-background-black .teaser .teaser-title,
.frame-background-bg-image .teaser .teaser-title {
    color: $white;
}
