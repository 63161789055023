.teaser {
  position: relative;
}
.teaser a {
  text-decoration: none;
}
.teaser-row .teaser {
  margin-bottom: 10px;
}
@media (max-width: 767.98px) {
  .teaser-row .teaser {
    max-width: 336px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 575.98px) {
  .teaser-row .teaser {
    max-width: 294px;
  }
}
@media (min-width: 992px) {
  .teaser-row .teaser {
    margin-bottom: 20px;
  }
}
.teaser .teaser-title {
  position: absolute;
  left: 0;
  z-index: 100;
  transform-origin: left;
  bottom: 40px;
  color: #000000;
  white-space: nowrap;
  font-size: 2rem;
  font-family: "Barlow Condensed", sans-serif, sans-serif;
}
@media (min-width: 768px) {
  .teaser .teaser-title {
    left: -10px;
  }
}

.teaser-top {
  position: relative;
  height: 0;
  overflow: hidden;
  color: #fff;
  padding-bottom: 150%;
}
.teaser-top {
  font-size: 1.375rem;
}
@media (min-width: 576px) {
  .teaser-top {
    font-size: calc(1.375rem + 1.625 * ((100vw - 36rem) / 39));
  }
}
@media (min-width: 1200px) {
  .teaser-top {
    font-size: 3rem;
  }
}
.teaser-top:hover picture img {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
.teaser-top img {
  filter: brightness(0.7);
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
.teaser-top picture::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.55) 0, rgba(0, 0, 0, 0) 100%);
}
.teaser-nav .teaser-top {
  padding-bottom: 75%;
}
.teaser-nav .teaser-top .teaser-title {
  font-size: 1.375rem;
}
.teaser-nav .teaser-top .teaser-title .teaser-arrow {
  top: -3px;
}
.teaser-top .img-fluid {
  min-width: 100%;
}
.teaser-top .teaser-title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 1.1;
  padding: 1.75rem 1rem;
  color: #fff;
  font-weight: 200;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .teaser-top .teaser-title {
    padding: 1.75rem 1.5rem;
  }
}
.teaser-top .teaser-title .teaser-arrow {
  position: relative;
  white-space: nowrap;
  top: -7px;
  font-size: 1.25rem;
}

.teaser-bottom {
  margin-top: 0.875rem;
  padding: 0 1rem;
  color: #000000;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  .teaser-bottom {
    padding: 0 1.5rem;
  }
}
.teaser-nav .teaser-bottom {
  color: #000000;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .teaser-nav .teaser-title .teaser-arrow {
    font-size: 0.9375rem;
  }
  .teaser-nav .teaser-subtitle {
    font-size: 1rem;
  }
}
.teaser-splide {
  padding-top: 50px;
  padding-bottom: 50px;
}
.teaser-splide .splide__arrows {
  padding-top: 100px;
}

.teaser-splide .splide__list .splide__slide:nth-of-type(2n) {
  transform: translateY(50px);
}

.teaser-splide .splide__list .splide__slide:nth-of-type(3n) {
  transform: translateY(25px);
}

.frame-background-black .splide__arrow svg,
.frame-background-bg-image .splide__arrow svg {
  filter: brightness(0) invert(1);
}

.dropdown-menu-content .teaser .teaser-title,
.frame-background-black .teaser .teaser-title,
.frame-background-bg-image .teaser .teaser-title {
  color: #fff;
}